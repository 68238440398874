import React, { useEffect, useState } from "react";
import { history, useModel } from "umi";

import NoticeIcon from "./NoticeIcon";
import styles from "./index.less";
import { getTraceMsgUnreadList, MarkTraceMsg } from "./service";
import { Button, Empty, message } from "antd";

export interface GlobalHeaderRightProps {
  fetchingNotices?: boolean;
  onNoticeVisibleChange?: (visible: boolean) => void;
  onNoticeClear?: (tabName?: string) => void;
}

const tracelist = [
  {
    title: "2023-01-23 22:22:46新增一条偏离预警订单",
    orderSerial: "O202303060035007",
    traceSerial: "YD23030613489272",
    startAddress: "天津市市辖区和平区条子哥私家菜",
    endAddress: "天津市天津市天津大礼堂",
    name: "龚家祥",
    phone: "17724483307",
  },
  {
    title: "2023-01-24 22:22:46新增一条偏离预警订单",
    orderSerial: "O202303060035007",
    traceSerial: "YD23030613489272",
    startAddress: "天津市市辖区和平区条子哥私家菜",
    endAddress: "天津市天津市天津大礼堂",
    name: "龚家祥",
    phone: "17724483307",
  },
  {
    title: "2023-01-24 22:22:46新增一条偏离预警订单",
    orderSerial: "O202303060035007",
    traceSerial: "YD23030613489272",
    startAddress: "天津市市辖区和平区条子哥私家菜",
    endAddress: "天津市天津市天津大礼堂",
    name: "龚家祥",
    phone: "17724483307",
  },
];

const NoticeIconView = () => {
  const { initialState } = useModel("@@initialState");
  const { currentUser } = initialState || {};
  // const [notices, setNotices] = useState<API.NoticeIconData[]>([]);
  const [traceNoticeList, setTraceNoticeList] = useState<any[]>([]);

  useEffect(() => {
    // queryNotices().then(({ data }) => setNotices(data));
    queryUnreadList();
  }, []);

  const queryUnreadList = () => {
    getTraceMsgUnreadList({}).then((res) => {
      console.log("====================================");
      console.log(res);
      console.log("====================================");
      if (res?.ret_info?.ret == 0) {
        setTraceNoticeList(res.msg);
      }
    });
  };

  const jumpToTracePage = (orderSerial: string, traceSerial: string): void => {
    history.push(`/order/transList/detail/${traceSerial}/${orderSerial}`);
  };

  return (
    <NoticeIcon
      className={styles.action}
      bell={
        <i
          className="iconfont icon-xinxi"
          style={{ color: "#fff", fontSize: 30, verticalAlign: "middle" }}
          onClick={() => {
            history.push(`/order/newList`);
          }}
        ></i>
      }
      count={traceNoticeList.length}
      // onItemClick={(item) => {
      //   changeReadState(item.id);
      // }}
      // onClear={(title: string, key: string) => clearReadState(title, key)}
      loading={false}
      clearText={null}
      viewMoreText={null}
      // onViewMore={() => message.info('Click on view more')}
      clearClose
    // children={<div>123456</div>}
    >
      <div style={{ padding: "10px 0", overflow: "hidden", background: '#fff' }}>
        <div className={styles.traceNotice}>
          {traceNoticeList?.length ? (
            <>
              <div className={styles.traceNoticeTitle}>
                轨迹偏移消息
                <p
                  onClick={() => {
                    const list = traceNoticeList.map((item) => item.msg_serial);
                    MarkTraceMsg({ msg_serial: list }).then((res) => {
                      console.log("====================================");
                      console.log(res);
                      console.log("====================================");
                      if (res?.ret_info?.ret == 0) {
                        message.success("全部信息已标记为已读");
                        queryUnreadList();
                      }
                    });
                  }}
                >
                  全部标记为已读
                </p>
              </div>
              {traceNoticeList.map((item, index) => (
                <div
                  className={styles.traceNoticeItem}
                  onClick={() => {
                    MarkTraceMsg({ msg_serial: [item.msg_serial] }).then(
                      (res) => {
                        console.log("====================================");
                        console.log(res);
                        console.log("====================================");
                        if (res?.ret_info?.ret == 0) {
                          queryUnreadList();
                        }
                      }
                    );
                    jumpToTracePage(item.order_serial, item.shipping_serial);
                  }}
                  key={index}
                >
                  <h4>{item.subject}</h4>
                  <p>{"订单号：" + item.order_serial}</p>
                  <p>{"运单号：" + item.shipping_serial}</p>
                  <p>
                    {"起始地：" + `${item.s_province_name}${item.s_city_name}`}
                  </p>
                  <p>
                    {"目的地：" + `${item.e_province_name}${item.e_city_name}`}
                  </p>
                  <p>{"承运商姓名：" + item.carrier_name}</p>
                  <p>{"承运商姓名：" + item.carrier_phone}</p>
                </div>
              ))}
            </>
          ) : (
            <>
              <Empty description="暂无消息" />
            </>
          )}
        </div>
      </div>
    </NoticeIcon>
  );
};

export default NoticeIconView;
